import React from "react";
import {
  HStack,
  Link,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import "@fontsource/roboto";
import { ExternalLinkIcon } from "@chakra-ui/icons";

import LongPressTooltip from "../LongPressTooltip";

import * as lib from "../../lib/lib";

const Scanners = ({ bifrost }) => {
  if (!bifrost) return null;

  return (
    <Table size="sm">
      <Thead>
        <Tr>
          <Th p={1} py={2} pr={3}></Th>
          <Th p={1} py={2} pr={3}>
            <Text>Scan</Text>
          </Th>
          <Th p={1} py={2} pr={3}>
            <Text>Sync</Text>
          </Th>
          <Th p={1} py={2} pr={3} textAlign="right">
            <Text>Tip</Text>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {Object.entries(bifrost).map(([chain, status]) => (
          <Tr key={chain}>
            <Th p={1} py={2} pr={3}>
              <Text>{chain}</Text>
            </Th>
            <Td p={1} py={2} pr={3}>
              <LongPressTooltip
                label={lib.millisecondsToDHMS(
                  lib.blockMilliseconds(chain) * status.scanner.consensus_lag,
                  1,
                )}
                justify="left"
                placement="left"
              >
                <Text>{status.scanner.consensus_lag}</Text>
              </LongPressTooltip>
            </Td>
            <Td p={1} py={2} pr={3}>
              <LongPressTooltip
                label={lib.millisecondsToDHMS(
                  lib.blockMilliseconds(chain) * status.chain.consensus_lag,
                  1,
                )}
                justify="left"
                placement="left"
              >
                <Text>{status.chain.consensus_lag}</Text>
              </LongPressTooltip>
            </Td>
            <Td p={1} py={2} pr={3} textAlign="right">
              <Link
                target="_blank"
                href={lib.blockExplorerLink(chain, status.chain.height)}
              >
                <HStack justify="right">
                  <Text fontFamily="mono">{status.chain.height}</Text>
                  <ExternalLinkIcon />
                </HStack>
              </Link>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default Scanners;

import React from "react";
import { Box } from "@chakra-ui/react";
import "@fontsource/roboto";

import Transactions from "../components/Transactions";

import * as lib from "../lib/lib";
import * as config from "../lib/config";

const Pending = ({ pools }) => {
  // ------------------------------ ui state ------------------------------

  const heightRef = React.useRef(0);
  const poolsRef = React.useRef(pools);

  if (config.getHeight() > 0) {
    heightRef.current = config.getHeight();
  }

  // ------------------------------ background updates ------------------------------

  const [pending, setPending] = React.useState(null);
  const pendingInterval = React.useRef(null);
  React.useEffect(() => {
    const updatePending = async () => {
      const outboundReq = lib.getThornode(`thorchain/queue/outbound`);
      const scheduledReq = lib.getThornode(`thorchain/queue/scheduled`);
      const streamingReq = lib.getThornode(`thorchain/swaps/streaming`);
      const [outboundRes, scheduledRes, streamingRes] = await Promise.all([
        outboundReq,
        scheduledReq,
        streamingReq,
      ]);

      for (const res of [outboundRes, scheduledRes, streamingRes]) {
        const newHeight = parseInt(res.headers["x-thorchain-height"], 10);
        if (!config.getHeight() > 0) {
          heightRef.current = Math.max(heightRef.current, newHeight);
        }
      }

      let pending = [];
      if (outboundRes?.data) {
        pending = pending.concat(
          outboundRes.data.map((pending) => ({
            ...pending,
            type: "Outbound",
          })),
        );
      }
      if (scheduledRes?.data) {
        pending = pending.concat(
          scheduledRes.data.map((pending) => ({
            ...pending,
            type: "Scheduled",
          })),
        );
      }
      if (streamingRes?.data) {
        pending = pending.concat(
          streamingRes.data.map((pending) => ({
            ...pending,
            type: "Streaming",
            sourceAsset: lib.parseAsset(pending.source_asset, poolsRef.current),
            targetAsset: lib.parseAsset(pending.target_asset, poolsRef.current),
          })),
        );
      }

      pending = pending.map((pending) =>
        pending.type === "Streaming"
          ? pending
          : {
              asset: lib.parseAsset(pending.coin.asset, poolsRef.current),
              amount: pending.coin.amount,
              to: pending.to_address,
              memo: pending.memo,
              source: pending.memo.split(":")[1],
              type: pending.type,
              height: pending.height,
              vault: pending.vault_pub_key,
              cloutSpent: parseFloat(pending.clout_spent),
            },
      );

      setPending(pending);
    };

    if (!pendingInterval.current) {
      updatePending();
      pendingInterval.current = setInterval(updatePending, 20000); // 20 seconds
    }
  }, []);

  return (
    <Box width="100%" maxWidth="3xl">
      <Transactions
        height={heightRef.current}
        pending={pending}
        pools={pools}
        all={true}
      />
    </Box>
  );
};

export default Pending;

import { useState } from "react";
import { FormControl, HStack, Input, Button } from "@chakra-ui/react";
import * as lib from "../../lib/lib";

export default function SearchLP() {
  const [addressInput, setAddressInput] = useState("");

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (lib.isValidAddress(addressInput)) {
          window.location = `lp?address=${addressInput}`;
        }
      }}
    >
      <FormControl
        isInvalid={addressInput !== "" && !lib.isValidAddress(addressInput)}
      >
        <HStack>
          <Input
            type="text"
            placeholder="LP Address (Use `thor` address if Dual LP)"
            value={addressInput}
            onChange={(e) => setAddressInput(e.target.value)}
          />
          <Button
            type="submit"
            isDisabled={!lib.isValidAddress(addressInput)}
            colorScheme="blue"
          >
            Track
          </Button>
        </HStack>
      </FormControl>
    </form>
  );
}

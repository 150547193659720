import { useState } from "react";
import { Accordion, Button, Center } from "@chakra-ui/react";

import LPAccordionItem from "./LPAccordionItem";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

export default function LPAccordion({ lps }) {
  const [expandedIndices, setExpandedIndices] = useState([]);

  const isAllExpanded = expandedIndices.length === lps.length;

  const handleExpandAll = () => {
    if (isAllExpanded) setExpandedIndices([]);
    else setExpandedIndices(lps.map((_, i) => i));
  };

  const handleExpandOne = (i) => {
    const newExpandedIndices = expandedIndices.filter((j) => i !== j);
    if (newExpandedIndices.length === expandedIndices.length)
      newExpandedIndices.push(i);
    setExpandedIndices(newExpandedIndices);
  };

  return (
    <>
      <Center>
        <Button size="xs" mb={1} onClick={handleExpandAll}>
          {isAllExpanded ? (
            <ChevronDownIcon mr={1} />
          ) : (
            <ChevronUpIcon mr={1} />
          )}{" "}
          All
        </Button>
      </Center>
      <Accordion allowMultiple index={expandedIndices}>
        {lps.map((lp, i) => (
          <LPAccordionItem
            key={`lp-accordion-item-${i}`}
            lp={lp}
            index={i}
            onClick={() => handleExpandOne(i)}
          />
        ))}
      </Accordion>
    </>
  );
}

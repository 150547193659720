import React from "react";
import {
  Alert,
  AlertIcon,
  Badge,
  Box,
  Card,
  Divider,
  Flex,
  HStack,
  SkeletonCircle,
  Switch,
  TableContainer,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { ExternalLinkIcon, LockIcon } from "@chakra-ui/icons";
import "@fontsource/roboto";

import TxTable from "./TxTable";
import LongPressTooltip from "./LongPressTooltip";

import * as lib from "../lib/lib";

const Transactions = ({ height, pending, pools, all = false }) => {
  const [cloutSwitch, setCloutSwitch] = React.useState({
    Scheduled: false,
    Outbound: false,
  });

  const streaming =
    pending?.filter((x) => x.type === "Streaming").length === 0 ? (
      <Alert opacity={0.8} fontSize="sm" mb={1} status="info">
        <AlertIcon />
        No Streaming Swaps
      </Alert>
    ) : (
      <TableContainer>
        <TxTable
          columns={[
            {
              Header: "Age",
              accessor: "age",
              Cell: ({ value }) => lib.millisecondsToDHMS(value, 1),
            },
            {
              Header: "ETA",
              accessor: "eta",
              Cell: ({ value }) => lib.millisecondsToDHMS(value, 1),
            },
            {
              Header: "Value",
              accessor: "usdValue",
              Cell: ({ value }) => lib.usdString(value, true),
            },
            {
              Header: "Source",
              accessor: "sourceAsset",
            },
            {
              Header: "Target",
              accessor: "targetAsset",
            },
            // Avoid some noise for now.
            { Header: "Count", accessor: "count" },
            { Header: "Destination", accessor: "destination" },
            {
              Header: "TxID",
              accessor: "txid",
              Cell: ({ value }) => "..." + value.slice(-6),
            },
            {
              Header: "",
              accessor: "extra",
              Cell: (
                <Flex justifyContent="flex-end">
                  <ExternalLinkIcon />
                </Flex>
              ),
            },
          ]}
          data={
            pending
              ?.filter((x) => x.type === "Streaming")
              .map((row, index) => ({
                id: index,
                age: row.count * row.interval * lib.blockMilliseconds("THOR"),
                eta:
                  (row.quantity - row.count) *
                  row.interval *
                  lib.blockMilliseconds("THOR"),
                usdValue: lib.amountToUSD(
                  parseInt(row.deposit),
                  row.sourceAsset,
                  pools,
                ),
                progress: `${row.count}/${row.quantity}`,
                count: row.count,
                txid: row.tx_id,
                sourceAsset: `${lib.assetChainSymbol(row.sourceAsset)}`,
                targetAsset: `${lib.assetChainSymbol(row.targetAsset)}`,
                destination: lib.shortAddress(
                  row.destination,
                  row.targetAsset.chain,
                ),
              })) || []
          }
        />
      </TableContainer>
    );

  const getQueue = (type) =>
    pending?.filter((x) => x.type === type).length === 0 ? (
      <Alert opacity={0.8} fontSize="sm" mb={1} status="info">
        <AlertIcon />
        No {type === "Scheduled" ? "Scheduled" : "Pending"} Outbounds
      </Alert>
    ) : (
      <>
        <HStack
          justifyContent="space-evenly"
          borderRadius="sm"
          bg="#f5f5f5"
          width="full"
          fontSize="sm"
          p={2}
          mb={3}
        >
          <Box style={{ whiteSpace: "nowrap" }}>
            {`Value: ${lib.usdString(
              pending
                ?.filter((x) => x.type === type)
                .map((row, index) =>
                  lib.amountToUSD(row.amount, row.asset, pools),
                )
                .reduce((acc, amount) => {
                  if (amount) {
                    return acc + amount;
                  }
                  return acc;
                }, 0),
              true,
            )}`}
          </Box>
          <Text>|</Text>
          <Box style={{ whiteSpace: "nowrap" }}>
            {`Clout: ${(
              pending
                ?.filter((x) => x.type === type)
                .reduce((acc, row) => {
                  if (row?.cloutSpent) {
                    return acc + row.cloutSpent;
                  }
                  return acc;
                }, 0) / 1e8
            ).toLocaleString("en-US", {
              maximumFractionDigits: 0,
            })}`}
            <Switch
              ml={2}
              size="sm"
              isChecked={cloutSwitch[type]}
              onChange={() =>
                setCloutSwitch((state) => ({
                  ...state,
                  [type]: !state[type],
                }))
              }
            />
          </Box>
        </HStack>
        <Divider my={2} />
        <TableContainer>
          <TxTable
            columns={[
              {
                Header: "",
                accessor: "vault",
                Cell: ({ row }) => (
                  <HStack p={0} justifyContent="flex-start">
                    <LongPressTooltip
                      placement="top"
                      label={row.original.vault.slice(-4)}
                    >
                      <LockIcon
                        m={0}
                        mr={1}
                        p={0}
                        color={lib.colorizeVault(row.original.vault.slice(-4))}
                      />
                    </LongPressTooltip>
                  </HStack>
                ),
              },
              {
                Header: type === "Scheduled" ? "ETA" : "Age",
                accessor: "etaOrAge",
                Cell: ({ value }) => lib.millisecondsToDHMS(value, 1),
              },
              { Header: "Type", accessor: "type" },
              {
                Header: "Value",
                accessor: "usdValue",
                Cell: ({ value }) => lib.usdString(value, true),
              },
              { Header: "Asset", accessor: "asset" },
              {
                Header: "Clout Spent",
                accessor: "cloutSpent",
                show: cloutSwitch[type],
                Cell: ({ value }) =>
                  value / 1e8 > 0
                    ? (value / 1e8).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : null,
              },
              { Header: "Destination", accessor: "destination" },
              {
                Header: "TxID",
                accessor: "txid",
                Cell: ({ value }) => "..." + value.slice(-6),
              },
              {
                Header: "",
                accessor: "extra",
                Cell: ({ row }) => (
                  <HStack justifyContent="flex-end">
                    <ExternalLinkIcon />
                  </HStack>
                ),
              },
            ]}
            data={
              pending
                ?.filter(
                  (x) =>
                    x.type === type &&
                    (type === "Outbound" || x.height > height),
                )
                .map((row, index) => ({
                  id: index,
                  etaOrAge:
                    Math.abs(row.height - height) *
                    lib.blockMilliseconds("THOR"),
                  type: row.memo.split(":")[0],
                  asset: `${lib.assetChainSymbol(row.asset)}`,
                  usdValue: lib.amountToUSD(row.amount, row.asset, pools),
                  cloutSpent: row.cloutSpent,
                  destination: lib.shortAddress(row.to, row.asset.chain),
                  txid: row.source,
                  vault: row.vault,
                })) || []
            }
          />
        </TableContainer>
      </>
    );

  const scheduled = getQueue("Scheduled");
  const outbound = getQueue("Outbound");

  // all returns separate cards for each type
  if (all) {
    const cards = [
      ["Streaming", streaming],
      ["Scheduled", scheduled],
      ["Outbound", outbound],
    ];
    return (
      <>
        {cards.map(([type, content]) => (
          <Card variant="outline" my={3} p={2}>
            <Tabs overflowX="auto">
              <TabList>
                <Tab py={1} px={2}>
                  <Text>{type}</Text>
                  {pending?.filter((x) => x.type === type).length > 0 && (
                    <Badge
                      ml={1}
                      px={1.5}
                      py={0.5}
                      borderRadius="full"
                      colorScheme="gray"
                    >
                      {pending?.filter((x) => x.type === type).length}
                    </Badge>
                  )}
                </Tab>
                <HStack width="full" justifyContent="flex-end">
                  <SkeletonCircle size={2} mr={2} />
                </HStack>
              </TabList>
              <TabPanels>
                <TabPanel px={1} pb={0}>
                  {content}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Card>
        ))}
      </>
    );
  }

  return (
    <Card variant="outline" p={2}>
      <Tabs defaultIndex={2} overflowX="auto">
        <TabList>
          {["Streaming", "Scheduled", "Outbound"].map((type, index) => (
            <Tab py={1} px={2} key={index}>
              <Text>{type}</Text>
              {pending?.filter((x) => x.type === type).length > 0 && (
                <Badge
                  ml={1}
                  px={1.5}
                  py={0.5}
                  borderRadius="full"
                  colorScheme="gray"
                >
                  {pending?.filter((x) => x.type === type).length}
                </Badge>
              )}
            </Tab>
          ))}
          <HStack width="full" justifyContent="flex-end">
            <SkeletonCircle size={2} mr={2} />
          </HStack>
        </TabList>

        <TabPanels>
          <TabPanel px={1} pb={0}>
            {streaming}
          </TabPanel>
          <TabPanel px={1} pb={0}>
            {scheduled}
          </TabPanel>
          <TabPanel px={1} pb={0}>
            {outbound}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Card>
  );
};

export default Transactions;

import { useEffect, useRef, useState } from "react";
import { AbsoluteCenter, Box, Card, Spinner } from "@chakra-ui/react";

import * as lib from "../lib/lib";
import TotalLPStats from "../components/lp/TotalLPStats";
import LPAccordion from "../components/lp/LPAccordion";

// ------------------------------ CONSTANTS ----------------------------

const ADDRESSES_LIMIT = 1;

// ------------------------------ HELPERS ------------------------------
function convertDecimal(nativeAmount, decimals) {
  return parseFloat(nativeAmount) / Math.pow(10, decimals);
}

function makeLP(data, network, pools) {
  if (
    data.units === "0" &&
    data.pending_rune === "0" &&
    data.pending_asset === "0"
  )
    return null;

  const [pool] = pools.filter((p) => p.asset === data.asset);
  const runeDecimals = 8;
  const assetDecimals = parseInt(pool.assetDecimals ?? 8);

  const usdPriceRune = convertDecimal(network.rune_price_in_tor, runeDecimals);
  const usdPriceAsset = convertDecimal(pool.asset_tor_price, assetDecimals);

  let { asset, rune_address: addressRune, asset_address: addressAsset } = data;

  if (!addressRune) addressRune = null;
  if (!addressAsset) addressAsset = null;

  const chain = asset.split(".")[0];
  const shortAsset = asset.split("-")[0];

  const lastAddHeight = data.last_add_height ?? null;
  const lastWithdrawHeight = data.last_withdraw_height ?? null;
  const poolUnits = parseInt(data.units);
  const pendingRune = convertDecimal(data.pending_rune, runeDecimals);
  const pendingAsset = convertDecimal(data.pending_asset, assetDecimals);
  const depositedRune = convertDecimal(data.rune_deposit_value, runeDecimals);
  const depositedAsset = convertDecimal(
    data.asset_deposit_value,
    assetDecimals,
  );
  const redeemableRune = convertDecimal(data.rune_redeem_value, runeDecimals);
  const redeemableAsset = convertDecimal(
    data.asset_redeem_value,
    assetDecimals,
  );

  const depositedUsd =
    depositedRune * usdPriceRune + depositedAsset * usdPriceAsset;
  const redeemableUsd =
    redeemableRune * usdPriceRune + redeemableAsset * usdPriceAsset;
  const pendingUsd = pendingRune * usdPriceRune + pendingAsset * usdPriceAsset;

  const lp = {
    chain,
    asset,
    shortAsset,
    addressRune,
    addressAsset,
    lastAddHeight,
    lastWithdrawHeight,
    poolUnits,
    usdPriceAsset,
    usdPriceRune,
    pendingAsset,
    pendingRune,
    pendingUsd,
    depositedAsset,
    depositedRune,
    depositedUsd,
    redeemableAsset,
    redeemableRune,
    redeemableUsd,
  };

  return lp;
}

function getLPTotals(lps) {
  return lps.reduce(
    (a, lp) => {
      // Get USD totals.
      ["pendingUsd", "depositedUsd", "redeemableUsd"].forEach(
        (k) => (a[k] += lp[k]),
      );

      return a;
    },
    { pendingUsd: 0, depositedUsd: 0, redeemableUsd: 0 },
  );
}

// ------------------------------ COMPONENT ------------------------------

export default function LP({ addresses }) {
  const pools = useRef(null);
  const network = useRef(null);
  const [lps, setLPs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getLP = async () => {
      const uniqueAddresses = Array.from(new Set(addresses));
      if (uniqueAddresses.length > ADDRESSES_LIMIT) {
        setIsLoading(false);
        return;
      }

      const poolsRes = await lib.getThornode("thorchain/pools");
      pools.current = poolsRes.data.toSorted((a, b) =>
        a.asset.localeCompare(b.asset),
      );

      // Get RUNE Price data
      const networkRes = await lib.getThornode("thorchain/network");
      network.current = networkRes.data;

      // Loop through all the possible LPs and filter out the zero balances.
      const reqs = [];
      for (const a of uniqueAddresses) {
        // Test address to see that it is a valid address by running it
        // against a single pool.
        const isValidAddress = await lib
          .getThornode(`thorchain/pool/BTC.BTC/liquidity_provider/${a}`)
          .then(() => true)
          .catch((err) => {
            if (err.response && err.response.status === 404) return false;
            throw err;
          });

        if (!isValidAddress) continue;
        for (const p of pools.current) {
          reqs.push(
            lib
              .getThornode(`thorchain/pool/${p.asset}/liquidity_provider/${a}`)
              .then((res) => res.data)
              .catch((err) => {
                if (err.response && err.response.status === 404) return null;
                throw err;
              }),
          );
        }
      }
      if (pools.current && network.current) {
        const lpRes = await Promise.all(reqs);

        // Deduplicate LPs when user inputs both addresses in an LP.
        const newLPsMap = lpRes.reduce((a, data) => {
          if (data !== null) {
            const lp = makeLP(data, network.current, pools.current);
            if (lp !== null) {
              const key = [lp.asset, lp.addressAsset, lp.addressRune].concat(
                ":",
              );
              a[key] = lp;
            }
          }
          return a;
        }, {});

        setLPs(Object.values(newLPsMap));
        setIsLoading(false);
      }
    };

    getLP();
  }, [addresses]);

  const lpTotals = getLPTotals(lps);
  const uniqueAddresses = Array.from(new Set(addresses));

  return (
    <Box>
      <Card variant="outline" width="sm" p={3}>
        {isLoading && (
          <AbsoluteCenter>
            <Spinner
              thickness="2px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xs"
            />
          </AbsoluteCenter>
        )}
        {!isLoading && uniqueAddresses.length > ADDRESSES_LIMIT && (
          <AbsoluteCenter>Too many addresses to track!</AbsoluteCenter>
        )}
        {!isLoading &&
          uniqueAddresses.length <= ADDRESSES_LIMIT &&
          lps.length > 0 && (
            <TotalLPStats addresses={uniqueAddresses} lpTotals={lpTotals} />
          )}
        {!isLoading &&
          uniqueAddresses.length <= ADDRESSES_LIMIT &&
          lps.length > 0 && <LPAccordion lps={lps} />}
      </Card>
    </Box>
  );
}

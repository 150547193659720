////////////////////////////////////////////////////////////////////////////////////////
// Types
////////////////////////////////////////////////////////////////////////////////////////

const AssetType = {
  L1: "l1",
  Synth: "synth",
  Trade: "trade",
};

////////////////////////////////////////////////////////////////////////////////////////
// Exports
////////////////////////////////////////////////////////////////////////////////////////

export { AssetType };

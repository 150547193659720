import { useState } from "react";
import { FormControl, HStack, Input, Button } from "@chakra-ui/react";
import * as lib from "../../lib/lib";

export default function SearchSwap() {
  const [txidInput, setTxidInput] = useState("");

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (lib.isValidTxID(lib.stripTxID(txidInput))) {
          window.location.pathname = lib.stripTxID(txidInput);
        }
      }}
    >
      <FormControl isInvalid={txidInput !== "" && !lib.isValidTxID(txidInput)}>
        <HStack>
          <Input
            type="text"
            placeholder="Swap Transaction ID"
            value={txidInput}
            onChange={(e) => setTxidInput(e.target.value)}
          />
          <Button
            type="submit"
            isDisabled={!lib.isValidTxID(txidInput)}
            colorScheme="blue"
          >
            Track
          </Button>
        </HStack>
      </FormControl>
    </form>
  );
}

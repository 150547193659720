import React from "react";
import {
  Box,
  Divider,
  HStack,
  Progress,
  Skeleton,
  Text,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { TriangleUpIcon, TriangleDownIcon } from "@chakra-ui/icons";
import "@fontsource/roboto";
import { TradingViewChart, TradingViewTicker } from "./TradingView";

import * as lib from "../../lib/lib";

const Economy = ({ network, pools, supply, lendingBalance, runepool }) => {
  if (!network || !supply || !pools) {
    return <Skeleton height="20px" mb={2} />;
  }

  const bonded = parseInt(network?.bondMetrics.totalActiveBond) / 1e8;
  const reserve = parseInt(network?.totalReserve) / 1e8;
  const pooled = parseInt(network?.totalPooledRune) / 1e8;
  const runeSupply =
    parseInt(supply?.supply.find((coin) => coin.denom === "rune").amount) / 1e8;
  const circulating = runeSupply - (bonded + reserve + pooled);
  const max = Math.max(bonded, reserve, pooled, circulating);

  // compute usd value of all saver and synth claims
  let saverUSDValue = 0;
  let synthUSDValue = 0;
  let assetUSDValue = 0;
  let lpUSDValue = 0;
  if (pools) {
    Object.values(pools).forEach((pool) => {
      if (pool.status !== "Available") {
        return;
      }
      const price = parseInt(pool.asset_tor_price);
      assetUSDValue += (price * parseInt(pool.balance_asset)) / 1e8;
      saverUSDValue += (price * parseInt(pool.savers_depth)) / 1e8;
      synthUSDValue += (price * parseInt(pool.synth_supply)) / 1e8;
      lpUSDValue +=
        (price * parseInt(pool.balance_asset) * parseInt(pool.LP_units)) /
        parseInt(pool.pool_units) /
        1e8;
    });

    // show non saver synth value
    synthUSDValue -= saverUSDValue;
  }
  const totalClaimUSDValue = saverUSDValue + synthUSDValue + lpUSDValue;

  // compute lending collateral
  let collateralUSDValue = 0;
  if (lendingBalance) {
    lendingBalance.coins.forEach((coin) => {
      const chain = coin.denom.split(".")[1];
      const asset = lib.parseAsset(`${chain}.${chain}`);
      collateralUSDValue += lib.amountToUSD(coin.amount, asset, pools);
    });
  }
  const standbyReserveBurn = 60_000_000;
  const runeBurned = 500_000_000 - standbyReserveBurn - runeSupply;
  console.log(runeBurned);
  const runeBurnedUSDValue = lib.amountToUSD(
    runeBurned * 1e8,
    lib.RuneAsset,
    pools,
  );

  return (
    <>
      <Box width="full" height="200px" position="relative">
        <Box
          position="absolute"
          width="full"
          height="full"
          zIndex="2"
          pointerEvents="none"
          bg="rgba(255, 255, 255, 0.5)"
        >
          <TradingViewTicker />
        </Box>
        <Box position="absolute" width="full" height="full" zIndex="1">
          <TradingViewChart />
        </Box>
      </Box>
      <Divider mb={1} mt={4} />
      <Table size="sm">
        <Tbody>
          <Tr>
            <Th p={1} py={2} pr={3}>
              Bonded
            </Th>
            <Td p={1} py={2}>
              <Text>
                {lib.usdString(
                  lib.amountToUSD(bonded * 1e8, lib.RuneAsset, pools),
                  true,
                )}
              </Text>
            </Td>
            <Td width="full">
              <Progress
                colorScheme="purple"
                flexGrow={1}
                opacity={0.5}
                value={bonded}
                size="xs"
                max={max}
              />
            </Td>
          </Tr>
          <Tr>
            <Th p={1} py={2} pr={3}>
              Reserve
            </Th>
            <Td p={1} py={2}>
              <Text>
                {lib.usdString(
                  lib.amountToUSD(reserve * 1e8, lib.RuneAsset, pools),
                  true,
                )}
              </Text>
            </Td>
            <Td width="full">
              <Progress
                colorScheme="purple"
                flexGrow={1}
                opacity={0.5}
                value={reserve}
                size="xs"
                max={max}
              />
            </Td>
          </Tr>
          <Tr>
            <Th p={1} py={2} pr={3}>
              Pooled
            </Th>
            <Td p={1} py={2}>
              <Text>
                {lib.usdString(
                  lib.amountToUSD(pooled * 1e8, lib.RuneAsset, pools),
                  true,
                )}
              </Text>
            </Td>
            <Td width="full">
              <Progress
                colorScheme="purple"
                flexGrow={1}
                opacity={0.5}
                value={pooled}
                size="xs"
                max={max}
              />
            </Td>
          </Tr>
          <Tr>
            <Th p={1} py={2} pr={3}>
              Cirulating
            </Th>
            <Td p={1} py={2}>
              <Text>
                {lib.usdString(
                  lib.amountToUSD(circulating * 1e8, lib.RuneAsset, pools),
                  true,
                )}
              </Text>
            </Td>
            <Td width="full">
              <Progress
                colorScheme="purple"
                flexGrow={1}
                opacity={0.5}
                value={circulating}
                size="xs"
                max={max}
              />
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <HStack
        justifyContent="center"
        borderRadius="sm"
        bg="#f5f5f5"
        width="full"
        fontSize="sm"
        p={2}
        mb={1}
        mt={3}
      >
        <Text fontWeight="bold">Yield</Text>
      </HStack>
      <Table size="sm">
        <Tbody>
          <Tr>
            <Th p={1} py={2} pr={3}>
              Bond
            </Th>
            <Td p={1} py={2}>
              <Text>{(parseFloat(network?.bondingAPY) * 100).toFixed(2)}%</Text>
            </Td>
          </Tr>
          <Tr>
            <Th p={1} py={2} pr={3}>
              Pool
            </Th>
            <Td p={1} py={2}>
              <Text>
                {(parseFloat(network?.liquidityAPY) * 100).toFixed(2)}%
              </Text>
            </Td>
          </Tr>
          <Tr>
            <Th p={1} py={2} pr={3}>
              Pendulum
            </Th>
            <Td width="full" p={1} py={2}>
              <HStack>
                <Text>
                  {(100 - parseFloat(network?.poolShareFactor) * 100).toFixed(
                    2,
                  )}
                  % nodes
                </Text>
                <Progress
                  flexGrow={1}
                  opacity={0.5}
                  value={100 - parseFloat(network?.poolShareFactor) * 100}
                  size="xs"
                  bg="orange"
                />
                <Text>
                  {(parseFloat(network?.poolShareFactor) * 100).toFixed(2)}%
                  pools
                </Text>
              </HStack>
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <HStack
        justifyContent="center"
        borderRadius="sm"
        bg="#f5f5f5"
        width="full"
        fontSize="sm"
        p={2}
        mb={1}
        mt={3}
      >
        <Text fontWeight="bold">Liquidity Claims</Text>
      </HStack>
      <Table size="sm">
        <Tbody>
          <Tr>
            <Th p={1} py={2} pr={3}>
              Savers
            </Th>
            <Td p={1} py={2} width="full">
              <Text>{lib.usdString(saverUSDValue / 1e8, true)}</Text>
            </Td>
          </Tr>
          <Tr>
            <Th p={1} py={2} pr={3}>
              Synths
            </Th>
            <Td p={1} py={2} width="full">
              <Text>{lib.usdString(synthUSDValue / 1e8, true)}</Text>
            </Td>
          </Tr>
          <Tr>
            <Th p={1} py={2} pr={3}>
              LPs
            </Th>
            <Td p={1} py={2} width="full">
              <Text>{lib.usdString(lpUSDValue / 1e8, true)}</Text>
            </Td>
          </Tr>
          <Tr>
            <Th p={1} py={2} pr={3}>
              Assets
            </Th>
            <Td p={1} py={2} width="full">
              <HStack>
                <Text>{lib.usdString(assetUSDValue / 1e8, true)}</Text>
                <Text color="gray">
                  (
                  {(Math.abs(assetUSDValue / totalClaimUSDValue) * 100).toFixed(
                    2,
                  )}
                  %)
                </Text>
              </HStack>
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <HStack
        justifyContent="center"
        borderRadius="sm"
        bg="#f5f5f5"
        width="full"
        fontSize="sm"
        p={2}
        mb={1}
        mt={3}
      >
        <Text fontWeight="bold">Lending</Text>
      </HStack>
      <Table size="sm">
        <Tbody>
          <Tr>
            <Th p={1} py={2} pr={3}>
              Collateral
            </Th>
            <Td p={1} py={2} width="full">
              <Text>{lib.usdString(collateralUSDValue, true)}</Text>
            </Td>
          </Tr>
          <Tr>
            <Th p={1} py={2} pr={3} whiteSpace="nowrap">
              Rune Burned
            </Th>
            <Td p={1} py={2} width="full">
              <Text>{lib.usdString(runeBurnedUSDValue, true)}</Text>
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <HStack
        justifyContent="center"
        borderRadius="sm"
        bg="#f5f5f5"
        width="full"
        fontSize="sm"
        p={2}
        mb={1}
        mt={3}
      >
        <Text fontWeight="bold">Protocol Owned Liquidity</Text>
      </HStack>
      <Table size="sm">
        <Tbody>
          <Tr>
            <Th p={1} py={2} pr={3} whiteSpace="nowrap">
              Reserve Value
            </Th>
            <Td p={1} py={2} width="full">
              <HStack>
                <Text>
                  {lib.usdString(
                    lib.amountToUSD(
                      parseInt(runepool?.reserve.value),
                      lib.RuneAsset,
                      pools,
                    ),
                    true,
                  )}
                </Text>
                <Text color="gray">
                  (
                  {(
                    Math.abs(
                      parseInt(runepool?.reserve.value) /
                        parseInt(runepool?.pol.value),
                    ) * 100
                  ).toFixed(2)}
                  %)
                </Text>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Th p={1} py={2} pr={3} whiteSpace="nowrap">
              Reserve Profit
            </Th>
            <Td p={1} py={2}>
              <HStack>
                <Text>
                  {lib.usdString(
                    lib.amountToUSD(
                      parseInt(runepool?.reserve.pnl),
                      lib.RuneAsset,
                      pools,
                    ),
                    true,
                  )}
                </Text>
                <Text color="gray">
                  (
                  {runepool?.reserve.pnl > 0 ? (
                    <TriangleUpIcon color="green.300" />
                  ) : (
                    <TriangleDownIcon color="red.300" />
                  )}{" "}
                  {(
                    Math.abs(
                      parseInt(runepool?.reserve.pnl) /
                        parseInt(runepool?.reserve.current_deposit),
                    ) * 100
                  ).toFixed(2)}
                  %)
                </Text>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Th p={1} py={2} pr={3} whiteSpace="nowrap">
              Provider Value
            </Th>
            <Td p={1} py={2}>
              <HStack>
                <Text>
                  {lib.usdString(
                    lib.amountToUSD(
                      parseInt(runepool?.providers.value),
                      lib.RuneAsset,
                      pools,
                    ),
                    true,
                  )}
                </Text>
                <Text color="gray">
                  (
                  {(
                    Math.abs(
                      parseInt(runepool?.providers.value) /
                        parseInt(runepool?.pol.value),
                    ) * 100
                  ).toFixed(2)}
                  %)
                </Text>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Th p={1} py={2} pr={3} whiteSpace="nowrap">
              Provider Profit
            </Th>
            <Td p={1} py={2}>
              <HStack>
                <Text>
                  {lib.usdString(
                    lib.amountToUSD(
                      parseInt(runepool?.providers.pnl),
                      lib.RuneAsset,
                      pools,
                    ),
                    true,
                  )}
                </Text>
                <Text color="gray">
                  (
                  {runepool?.providers.pnl > 0 ? (
                    <TriangleUpIcon color="green.300" />
                  ) : (
                    <TriangleDownIcon color="red.300" />
                  )}{" "}
                  {(
                    Math.abs(
                      parseInt(runepool?.providers.pnl) /
                        parseInt(runepool?.providers.current_deposit),
                    ) * 100
                  ).toFixed(2)}
                  %)
                </Text>
              </HStack>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </>
  );
};

export default Economy;

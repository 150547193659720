import {
  Card,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";

import SearchSwap from "./SearchSwap";
import SearchLP from "./SearchLP";

export default function Search() {
  return (
    <Card variant="outline" my={3} p={3}>
      <Tabs>
        <TabList>
          <Tab py={1} px={1}>
            Swap
          </Tab>
          <Tab py={1} px={2}>
            LP
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={1} pb={0}>
            <SearchSwap />
          </TabPanel>
          <TabPanel px={1} pb={0}>
            <SearchLP />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Card>
  );
}

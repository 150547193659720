import React from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Card,
  CardHeader,
  CardBody,
  HStack,
  Heading,
  Link,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import "@fontsource/roboto";
import * as lib from "../lib/lib";

const UnsupportedTransaction = ({ state }) => (
  <Card variant="outline" width="sm" p={3}>
    <CardHeader>
      <Alert
        status="error"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          Unsupported Transaction
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          Tracker only supports swaps and does not support trade assets.
        </AlertDescription>
      </Alert>
    </CardHeader>

    <CardBody>
      <Stack divider={<StackDivider />} spacing="4">
        <Box>
          <Heading size="xs" textTransform="uppercase">
            TX ID
          </Heading>
          <Text pt="2" fontSize="sm">
            {state.inbound.txid}
          </Text>
        </Box>
        <Box>
          <Heading size="xs" textTransform="uppercase">
            Chain
          </Heading>
          <Text pt="2" fontSize="sm">
            {state.chain}
          </Text>
        </Box>
        <Box>
          <Heading size="xs" textTransform="uppercase">
            From Address
          </Heading>
          <Text pt="2" fontSize="sm">
            {state.inbound.from}
          </Text>
        </Box>
        <Box>
          <Heading size="xs" textTransform="uppercase">
            Memo
          </Heading>
          <Text pt="2" fontSize="sm">
            {state.memo}
          </Text>
        </Box>
        <Box>
          <Link href={lib.runescanURL(`tx/${state.inbound.txid}`)} isExternal>
            <Button fontWeight="normal" size="sm" colorScheme="gray">
              <HStack>
                <Text>View on Runescan</Text>
                <ExternalLinkIcon />
              </HStack>
            </Button>
          </Link>
        </Box>
      </Stack>
    </CardBody>
  </Card>
);

export default UnsupportedTransaction;

import {
  Box,
  Flex,
  IconButton,
  Spacer,
  Stat,
  StatArrow,
  Table,
  Tbody,
  Th,
  Tr,
  Tooltip,
  useClipboard,
  Progress,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import * as lib from "../../lib/lib";

export default function LPAssetStats({
  asset,
  address,
  deposited,
  redeemable,
  isAsymLP,
}) {
  if (isAsymLP) {
    deposited *= 2;
    redeemable *= 2;
  }
  const delta = redeemable - deposited;
  const isIncreasing = delta >= 0;
  const pctChange = isIncreasing
    ? `${lib.formatDecimal((delta / deposited) * 100)}%`
    : `(${lib.formatDecimal((-delta / deposited) * 100)}%)`;
  const deltaStr = isIncreasing
    ? lib.formatDecimal(delta, 8)
    : `(${lib.formatDecimal(-delta, 8)})`;

  const { onCopy, hasCopied } = useClipboard(address);

  const shortAddress = lib.shortAddress(address, asset.split(".")[0]);
  const maxValue = Math.max(deposited, redeemable);
  console.log(address);

  return (
    <Table size="sm" width="100%" mb={3}>
      <Tbody>
        {address !== null && (
          <Tr>
            <Th p={0} width="100%" textAlign="left">
              <Flex>
                <Box py={2}>{asset.split(".")[0]} Address</Box>
                <Spacer />
                <Tooltip placement="top" label={address}>
                  <Box py={1}>
                    {hasCopied ? "Copied!" : shortAddress}
                    <IconButton
                      variant="subtle"
                      size="xs"
                      icon={<CopyIcon size="xs" m={0} p={0} />}
                      onClick={onCopy}
                    />
                  </Box>
                </Tooltip>
              </Flex>
            </Th>
          </Tr>
        )}
        <Tr>
          <Th p={0} py={2} width="100%">
            <Progress
              my={1}
              opacity={0.75}
              size="sm"
              colorScheme="yellow"
              value={deposited}
              max={maxValue}
            />
            <Flex>
              Deposited
              <Spacer />
              {lib.formatDecimal(deposited, 8)} {asset}
            </Flex>
          </Th>
        </Tr>
        <Tr>
          <Th p={0} py={2} width="100%">
            <Progress
              my={1}
              opacity={0.75}
              size="sm"
              hasStripe
              isAnimated
              colorScheme="green"
              value={redeemable}
              max={maxValue}
            />
            <Flex>
              Redeemable
              <Spacer />
              {lib.formatDecimal(redeemable, 8)} {asset}
            </Flex>
          </Th>
        </Tr>
        <Tr>
          <Th p={0} py={2} width="100%">
            <Flex>
              Net Change
              <Stat mx={1}>
                <StatArrow
                  type={isIncreasing ? "increase" : "decrease"}
                ></StatArrow>
              </Stat>
              <Spacer />
              <Box textColor={isIncreasing ? "green.400" : "red.400"}>
                {deltaStr} {asset}
              </Box>
            </Flex>
            <Flex>
              Pct Change
              <Stat mx={1}>
                <StatArrow
                  type={isIncreasing ? "increase" : "decrease"}
                ></StatArrow>
              </Stat>
              <Spacer />
              <Box textColor={isIncreasing ? "green.400" : "red.400"}>
                {pctChange}
              </Box>
            </Flex>
          </Th>
        </Tr>
      </Tbody>
    </Table>
  );
}

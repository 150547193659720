import React from "react";
import { Flex, Tooltip } from "@chakra-ui/react";

const LongPressTooltip = ({
  label,
  placement = "top",
  justify = "right",
  children,
}) => {
  const [showLongPressTooltip, setShowLongPressTooltip] = React.useState(false);
  let pressTimer = null;

  const handleStart = () => {
    pressTimer = setTimeout(() => {
      setShowLongPressTooltip(true);
    }, 200); // Delay for long press (200ms)
  };

  const handleEnd = () => {
    clearTimeout(pressTimer);
    setShowLongPressTooltip(false);
  };

  React.useEffect(() => {
    return () => {
      clearTimeout(pressTimer); // Clear timeout if the component unmounts
    };
  }, [pressTimer]);

  return (
    <Tooltip label={label} isOpen={showLongPressTooltip} placement={placement}>
      <Flex
        onTouchStart={handleStart}
        onTouchEnd={handleEnd}
        onMouseEnter={handleStart}
        onMouseLeave={handleEnd}
        justifyContent={justify}
        // CSS to disable text selection
        sx={{
          WebkitUserSelect: "none" /* Safari */,
          KhtmlUserSelect: "none" /* Konqueror HTML */,
          MozUserSelect: "none" /* Old versions of Firefox */,
          MsUserSelect: "none" /* Internet Explorer/Edge */,
          userSelect: "none" /* Chrome, Edge, Opera and Firefox */,
        }}
      >
        {children}
      </Flex>
    </Tooltip>
  );
};

export default LongPressTooltip;

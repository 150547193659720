import React from "react";
import {
  Flex,
  Card,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { CheckCircleIcon, InfoIcon, WarningIcon } from "@chakra-ui/icons";
import "@fontsource/roboto";

import LongPressTooltip from "./LongPressTooltip";

import * as lib from "../lib/lib";

const Chains = ({ height, mimir, inboundAddresses, width }) => {
  const globalPause =
    mimir?.NODEPAUSECHAINGLOBAL > height ? (
      <LongPressTooltip
        justify="center"
        placement="top"
        label={lib.pauseTooltip(mimir?.NODEPAUSECHAINGLOBAL, height)}
      >
        <WarningIcon color="red.400" />
      </LongPressTooltip>
    ) : null;

  return (
    <Card variant="outline" my={3} p={2}>
      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th p={1}></Th>
              {inboundAddresses?.map((chain, index) => (
                <Th
                  p={width < 600 ? 0 : 2}
                  style={{
                    maxWidth: "1px",
                    height: width < 600 && "50px",
                  }}
                  key={index}
                >
                  <Flex alignItems="end" justifyContent="center">
                    <Text transform={width < 600 && "rotate(-90deg)"}>
                      {chain.chain}
                    </Text>
                  </Flex>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Th p={1}>Scan</Th>
              {inboundAddresses?.map((chain, index) => (
                <Td p={2} textAlign="center" key={index}>
                  {globalPause ||
                    (chain.haltHeight > height ? (
                      <LongPressTooltip
                        justify="center"
                        placement="top"
                        label={lib.haltTooltip(chain.haltHeight, height)}
                      >
                        <InfoIcon color="orange.400" />
                      </LongPressTooltip>
                    ) : chain.haltHeight > 0 ? (
                      <WarningIcon color="red.400" />
                    ) : (
                      <CheckCircleIcon color="green.400" />
                    ))}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Th p={1}>Trade</Th>
              {inboundAddresses?.map((chain, index) => (
                <Td p={2} textAlign="center" key={index}>
                  {chain.haltTradingHeight > height ? (
                    <LongPressTooltip
                      justify="center"
                      placement="top"
                      label={lib.haltTooltip(chain.haltTradingHeight, height)}
                    >
                      <InfoIcon color="orange.400" />
                    </LongPressTooltip>
                  ) : chain.haltTradingHeight > 0 ? (
                    <Text>
                      <WarningIcon color="red.400" />
                    </Text>
                  ) : (
                    <CheckCircleIcon color="green.400" />
                  )}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Th p={1}>LP</Th>
              {inboundAddresses?.map((chain, index) => (
                <Td p={2} textAlign="center" key={index}>
                  {chain.haltLPHeight > height ? (
                    <LongPressTooltip
                      justify="center"
                      placement="top"
                      label={lib.haltTooltip(chain.haltLPHeight, height)}
                    >
                      <InfoIcon color="orange.400" />
                    </LongPressTooltip>
                  ) : chain.haltLPHeight > 0 ? (
                    <WarningIcon color="red.400" />
                  ) : (
                    <CheckCircleIcon color="green.400" />
                  )}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Th p={1}>Sign</Th>
              {inboundAddresses?.map((chain, index) => (
                <Td p={2} textAlign="center" key={index}>
                  {chain.haltSigningHeight > height ? (
                    <LongPressTooltip
                      justify="center"
                      placement="top"
                      label={lib.haltTooltip(chain.haltSigningHeight, height)}
                    >
                      <InfoIcon color="orange.400" />
                    </LongPressTooltip>
                  ) : chain.haltSigningHeight > 0 ? (
                    <Text>
                      <WarningIcon color="red.400" />
                    </Text>
                  ) : (
                    <CheckCircleIcon color="green.400" />
                  )}
                </Td>
              ))}
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default Chains;

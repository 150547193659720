import React from "react";
import {
  Box,
  HStack,
  Progress,
  Skeleton,
  Spinner,
  Text,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";

import LongPressTooltip from "../LongPressTooltip";

import * as lib from "../../lib/lib";

const Nodes = ({ height, mimir, network, thornodeNetwork, nodes }) => {
  if (!nodes || !mimir || !network) {
    return <Skeleton height="20px" mb={2} />;
  }

  // stats
  const churnPercent =
    100 -
    (100 * (parseInt(network?.nextChurnHeight) - height)) /
      mimir?.CHURNINTERVAL;
  const activeBond = parseInt(network?.bondMetrics.totalActiveBond) / 100000000;
  const standbyBond =
    parseInt(network?.bondMetrics.totalStandbyBond) / 100000000;

  // label nodes and convert ints
  const labeledNodes = nodes?.map((node) => ({
    ...node,
    label: `${node.node_operator_address.slice(-4)}:${node.node_address.slice(-4)}`,
    total_bond: parseInt(node.total_bond),
    leave_height: parseInt(node.leave_height),
    slash_points: parseInt(node.slash_points),
    active_block_height: parseInt(node.active_block_height),
  }));

  // group by status
  const activeNodes = labeledNodes?.filter((node) => node.status === "Active");
  const standbyNodes = labeledNodes?.filter(
    (node) => node.status === "Standby",
  );

  // find leaving nodes
  const leaving = activeNodes?.filter(
    (node) => node.leave_height > 0 && node.leave_height <= height,
  );

  let markable = activeNodes?.filter(
    (node) => node.leave_height === 0 || node.leave_height > height,
  );

  // find redline slash - mean slash points / 20
  const totalSlash = activeNodes?.reduce(
    (acc, node) => acc + node.slash_points,
    0,
  );
  const meanSlash = totalSlash / activeNodes?.length;
  const redlineSlash = meanSlash * mimir?.BADVALIDATORREDLINE;

  // if 3 or more nodes with future leave score, current set is marked
  if (markable?.filter((node) => node.leave_height > height).length >= 3) {
    markable = markable?.filter((node) => node.leave_height > height);
  }

  let worst = markable?.sort((a, b) => b.slash_points - a.slash_points)[0];
  if (worst?.slash_points === 0) {
    worst = null;
  }
  markable = markable?.filter(
    (node) => node.node_address !== worst?.node_address,
  );
  const redlined = markable?.filter(
    (node) => redlineSlash && node.slash_points >= redlineSlash,
  );
  markable = markable?.filter((node) => node.slash_points < redlineSlash);
  const low = markable?.sort((a, b) => a.total_bond - b.total_bond)[0];
  markable = markable?.filter(
    (node) => node.node_address !== low?.node_address,
  );
  const oldest = markable?.sort(
    (a, b) => a.active_block_height - b.active_block_height,
  )[0];

  // nodes joining
  const newNodes = mimir?.NUMBEROFNEWNODESPERCHURN + leaving?.length + 3;
  const joining = standbyNodes
    ?.filter((node) => node.total_bond > mimir?.MINIMUMBONDINRUNE)
    .sort((a, b) => b.total_bond - a.total_bond)
    .slice(0, newNodes);

  // node versions
  const sortedVersions = activeNodes?.map((node) => node.version).sort();
  const latestVersion = sortedVersions?.pop();
  const oldestVersion = sortedVersions?.shift();
  const latestCount = activeNodes?.filter(
    (node) => node.version === latestVersion,
  ).length;

  return (
    <TableContainer>
      <Table size="sm">
        <Tbody>
          <Tr>
            <Th p={1} py={2} pr={3}>
              Version
            </Th>
            <Td p={1} py={2} width="full">
              <HStack>
                <Text>{oldestVersion}</Text>
                <Progress
                  colorScheme="green"
                  flexGrow={1}
                  opacity={0.5}
                  value={latestCount}
                  size="xs"
                  max={activeNodes?.length}
                />
                {latestVersion !== oldestVersion && (
                  <Text>{latestVersion}</Text>
                )}
                <Text color={latestVersion !== oldestVersion && "gray"}>
                  {`(${latestCount}/${activeNodes?.length})`}
                </Text>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Th p={1} py={2} pr={3}>
              Bond
            </Th>
            <Td p={1} py={2} width="full">
              <HStack>
                <Text>{(activeBond / 1e6).toFixed(1)}M active</Text>
                <Progress
                  flexGrow={1}
                  opacity={0.5}
                  value={activeBond}
                  size="xs"
                  max={activeBond + standbyBond}
                  bg="orange"
                />
                <Text>{(standbyBond / 1e6).toFixed(1)}M standby</Text>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Th p={1} py={2} pr={3}>
              <HStack>
                <Text>Churn</Text>
                {mimir?.HALTCHURNING ? (
                  <LongPressTooltip
                    placement="right"
                    label="churning is halted"
                  >
                    <WarningIcon maxHeight="18px" ml={2} color="red.400" />
                  </LongPressTooltip>
                ) : null}
                {thornodeNetwork?.vaults_migrating ? (
                  <LongPressTooltip placement="right" label="churning">
                    <Spinner size="xs" />
                  </LongPressTooltip>
                ) : null}
              </HStack>
            </Th>
            <Td p={1} py={2} width="full">
              <HStack>
                <Text>
                  {lib.millisecondsToDHMS(
                    (parseInt(network?.nextChurnHeight) - height) *
                      lib.blockMilliseconds("THOR"),
                    2,
                  )}
                </Text>
                <Progress
                  flexGrow={1}
                  opacity={0.5}
                  value={churnPercent}
                  colorScheme="green"
                  size="xs"
                />
                <Text>{churnPercent.toFixed(2)}%</Text>
              </HStack>
            </Td>
          </Tr>
          {redlineSlash ? (
            <Tr>
              <Th p={1} py={2} pr={3}>
                Redline
              </Th>
              <Td p={1} py={2} width="full">
                <HStack>
                  <LongPressTooltip placement="top" label="worst slash">
                    <Text>{lib.localeString(worst?.slash_points)}</Text>
                  </LongPressTooltip>
                  <Progress
                    flexGrow={1}
                    opacity={0.5}
                    value={worst?.slash_points}
                    size="xs"
                    max={redlineSlash}
                    colorScheme="red"
                  />
                  <LongPressTooltip placement="top" label="redline slash">
                    <Text>{lib.localeString(redlineSlash)}</Text>
                  </LongPressTooltip>
                </HStack>
              </Td>
            </Tr>
          ) : null}
          {low || worst || oldest || leaving.length > 0 ? (
            <Tr>
              <Th p={1} py={2} pr={3}>
                Leaving
              </Th>
              <Td maxWidth="10px" p={1}>
                <Box overflowX="auto">
                  <HStack>
                    {leaving?.map((node, index) => (
                      <Text
                        borderRadius="sm"
                        bg="#f5f5f5"
                        fontSize="xs"
                        fontFamily="mono"
                        p={1}
                        px={1.5}
                        key={index}
                      >
                        {node.label}
                      </Text>
                    ))}
                    {low && (
                      <LongPressTooltip
                        placement="top"
                        label={`low bond: ${lib.localeString(low.total_bond / 1e8)}`}
                      >
                        <Text
                          borderRadius="sm"
                          bg="orange.100"
                          fontSize="xs"
                          fontFamily="mono"
                          p={1}
                          px={1.5}
                        >
                          {low.label}
                        </Text>
                      </LongPressTooltip>
                    )}
                    {worst && (
                      <LongPressTooltip
                        placement="top"
                        label={`worst: ${lib.localeString(worst.slash_points)} slash`}
                      >
                        <Text
                          borderRadius="sm"
                          bg="red.100"
                          fontSize="xs"
                          fontFamily="mono"
                          p={1}
                          px={1.5}
                        >
                          {worst.label}
                        </Text>
                      </LongPressTooltip>
                    )}
                    {redlined?.map((node, index) => (
                      <LongPressTooltip
                        key={index}
                        placement="top"
                        label={`redline: ${lib.localeString(node.slash_points)} slash`}
                      >
                        <Text
                          borderRadius="sm"
                          bg="red.200"
                          fontSize="xs"
                          fontFamily="mono"
                          p={1}
                          px={1.5}
                        >
                          {node.label}
                        </Text>
                      </LongPressTooltip>
                    ))}
                    {oldest && (
                      <LongPressTooltip
                        placement="top"
                        label={`oldest: ${lib.millisecondsToDHMS(
                          (height - oldest.active_block_height) *
                            lib.blockMilliseconds("THOR"),
                          2,
                        )}`}
                      >
                        <Text
                          borderRadius="sm"
                          bg="purple.100"
                          fontSize="xs"
                          fontFamily="mono"
                          p={1}
                          px={1.5}
                        >
                          {oldest.label}
                        </Text>
                      </LongPressTooltip>
                    )}
                  </HStack>
                </Box>
              </Td>
            </Tr>
          ) : null}
          <Tr>
            <Th p={1} py={2} pr={3}>
              Joining
            </Th>
            <Td maxWidth="10px" p={1}>
              <Box overflowX="auto">
                <HStack>
                  {joining?.map((node, index) => (
                    <Text
                      borderRadius="sm"
                      bg="#f5f5f5"
                      fontSize="xs"
                      fontFamily="mono"
                      p={1}
                      px={1.5}
                      key={index}
                    >
                      {node.label}
                    </Text>
                  ))}
                </HStack>
              </Box>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default Nodes;

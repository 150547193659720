import React, { useEffect, useRef, memo } from "react";

function TradingViewChart() {
  const container = useRef();
  const scriptAdded = useRef(false);

  useEffect(() => {
    if (scriptAdded.current) {
      return;
    }

    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
        {
          "symbols": [
            [
              "BINANCE:RUNEUSDT|7D"
            ]
          ],
          "chartOnly": true,
          "width": "100%",
          "height": "100%",
          "locale": "en",
          "colorTheme": "light",
          "autosize": true,
          "showVolume": false,
          "showMA": false,
          "hideDateRanges": true,
          "hideMarketStatus": true,
          "hideSymbolLogo": true,
          "scalePosition": "no",
          "scaleMode": "Normal",
          "fontFamily": "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
          "fontSize": "8",
          "noTimeScale": true,
          "valuesTracking": "1",
          "changeMode": "price-and-percent",
          "chartType": "area",
          "maLineColor": "#2962FF",
          "maLineWidth": 1,
          "maLength": 9,
          "lineWidth": 2,
          "lineType": 2,
          "dateRanges": [
            "1w|60"
          ]
        }`;
    container.current.appendChild(script);
    scriptAdded.current = true;
  }, []);

  return (
    <div className="tradingview-widget-container" ref={container}>
      <div className="tradingview-widget-container__widget"></div>
    </div>
  );
}

function TradingViewTicker() {
  const container = useRef();
  const scriptAdded = useRef(false);

  useEffect(() => {
    if (scriptAdded.current) {
      return;
    }

    // ticker script
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-single-quote.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
        {
          "symbol": "BINANCE:RUNEUSDT",
          "width": "100%",
          "height": "100%",
          "locale": "en",
          "colorTheme": "light",
          "isTransparent": true,
          "locale": "en"
        }`;
    container.current.appendChild(script);

    scriptAdded.current = true;
  }, []);

  return (
    <div className="tradingview-widget-container" ref={container}>
      <div className="tradingview-widget-container__widget"></div>
    </div>
  );
}

const memoTradingViewChart = memo(TradingViewChart);
const memoTradingViewTicker = memo(TradingViewTicker);

export {
  memoTradingViewChart as TradingViewChart,
  memoTradingViewTicker as TradingViewTicker,
};

import React from "react";
import { useTable, useSortBy } from "react-table";
import { Box, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";

const TransactionTable = ({ columns, data }) => {
  const [sortBy, setSortBy] = React.useState([]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy: controlledSortBy },
  } = useTable({ columns, data, initialState: { sortBy } }, useSortBy);

  // Update the external sort state when the internal state changes
  React.useEffect(() => {
    setSortBy(controlledSortBy);
  }, [controlledSortBy]);

  return (
    <Table {...getTableProps()} size="sm">
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) =>
              column.show !== false ? (
                <Th
                  p={1.5}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ArrowDownIcon />
                      ) : (
                        <ArrowUpIcon />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </Th>
              ) : null,
            )}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <Box
              as="tr"
              cursor="pointer"
              onClick={() => {
                window.location.pathname = row.original.txid;
              }}
              _hover={{ backgroundColor: "gray.50" }}
              {...row.getRowProps()}
            >
              {row.cells.map((cell) =>
                cell.column.show !== false ? (
                  <Td p={1.5} {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </Td>
                ) : null,
              )}
            </Box>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default TransactionTable;

import React from "react";
import { Box, ChakraProvider, theme } from "@chakra-ui/react";
import "@fontsource/roboto";

import Swap from "./views/Swap";
import Home from "./views/Home";
import Pending from "./views/Pending";
import LP from "./views/LP";

import * as lib from "./lib/lib";
import * as config from "./lib/config";

////////////////////////////////////////////////////////////////////////////////////////
// App
////////////////////////////////////////////////////////////////////////////////////////

function App() {
  // ------------------------------ params ------------------------------

  // get txid from first path param
  let path = window.location.pathname.split("/")[1];

  // strip 0x prefix
  path = lib.stripTxID(path);

  // ---------- query ----------

  // extract network and height from query parameters
  const params = new URLSearchParams(window.location.search);
  const queryNetwork = params.get("network");
  config.setNetwork(queryNetwork);
  const queryHeight = params.get("height");
  config.setHeight(queryHeight);

  // Add functionality to allow for multiple addresses, but limit to 1 for now.
  const addresses = [params.get("address")];

  // allow whitelisted logos
  let queryLogo = params.get("logo");
  const logoWhitelist = [
    "9r.png",
    "asgardex.png",
    "shapeshift.png",
    "thorwallet.svg",
    "trust.svg",
  ];
  if (queryLogo && !logoWhitelist.includes(queryLogo)) {
    queryLogo = null;
  }

  // ------------------------------ background intervals ------------------------------

  // state changes triggering render
  const [pools, setPools] = React.useState(null);

  // ------------------------------ background updates ------------------------------

  // ---------- network ----------

  const [network, setNetwork] = React.useState(null);
  React.useEffect(() => {
    const updateNetwork = async () => {
      setNetwork((await lib.getMidgard(`/v2/network`)).data);
    };
    updateNetwork();
    setInterval(updateNetwork, 300000); // 5 minutes
  }, []);

  // ------------------------------ background updates ------------------------------

  // ---------- pools (10m) ----------

  const updatePools = async () => {
    const data = (await lib.getThornode(`thorchain/pools`)).data;
    const pools = {};
    data.forEach((pool) => {
      pools[pool.asset] = pool;
      pools[pool.asset].asset = lib.parseAsset(pool.asset);
    });
    setPools(pools);
  };
  React.useEffect(() => {
    updatePools();
    setInterval(updatePools, 600000); // 10 minutes
  }, []);

  // ------------------------------ card content ------------------------------

  let content = null;
  if (path === "pending") {
    content = <Pending pools={pools} />;
  } else if (path === "lp") {
    content = <LP addresses={addresses} />;

    // do not change the rest of this logic since this affects
    // integration partners who depend on this path for swap tracking.
  } else if (!lib.isValidTxID(path)) {
    content = <Home network={network} pools={pools} />;
  } else {
    content = (
      <Swap txid={path} pools={pools} network={network} queryLogo={queryLogo} />
    );
  }

  // ------------------------------ wrapper ------------------------------

  return (
    <ChakraProvider theme={theme}>
      <Box
        display="flex"
        flexDirection="column"
        minHeight="100vh"
        justifyContent="center"
        alignItems="center"
        bg="gray.100"
        fontFamily="Roboto"
        p={2}
      >
        {content}
      </Box>
    </ChakraProvider>
  );
}

export default App;

import {
  Stat,
  StatArrow,
  StatHelpText,
  StatLabel,
  StatNumber,
  Tooltip,
  Heading,
} from "@chakra-ui/react";

import * as lib from "../../lib/lib";

export default function TotalLPStats({ addresses, lpTotals }) {
  const { depositedUsd, redeemableUsd, pendingUsd } = lpTotals;
  const delta = redeemableUsd - depositedUsd;
  const isIncreasing = delta >= 0;
  const deltaStr = isIncreasing
    ? `$${lib.formatDecimal(delta, 2)}`
    : `($${lib.formatDecimal(-delta, 2)})`;
  const pctChange = isIncreasing
    ? `${lib.formatDecimal((delta / depositedUsd) * 100)}%`
    : `(${lib.formatDecimal((-delta / depositedUsd) * 100)}%)`;

  return (
    <>
      <Stat>
        <StatLabel>
          {addresses.length > 0 &&
            addresses.map((a, i) => (
              <Heading key={`address-header-${i}`} size="xs">
                {a}
              </Heading>
            ))}
        </StatLabel>
        <Tooltip
          placement="bottom"
          label={`+ pending $${lib.formatDecimal(pendingUsd)}`}
        >
          <StatNumber>${lib.formatDecimal(redeemableUsd)}</StatNumber>
        </Tooltip>
        <StatHelpText
          opacity={1}
          textColor={isIncreasing ? "green.400" : "red.400"}
        >
          <StatArrow type={isIncreasing ? "increase" : "decrease"}></StatArrow>
          {deltaStr} | {pctChange}
        </StatHelpText>
      </Stat>
    </>
  );
}
